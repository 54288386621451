body {
  background-color: #f8fbfd;
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 18px 20px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 12px rgba(36, 50, 66, 0.1);
}

.mobile-button {
  display: none;
  float: right;
  outline: none;
  border: none;
  background-color: transparent;
}

li {
  float: left;
  display: block;
  margin: 0 15px;
}

.drawer {
  width: 300px;
  margin-left: -200px;
}

@media only screen and (max-width: 740px) {
  li:not(.logo) {
    display: none;
  }

  .mobile-button {
    display: flex;
  }
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 310px) {
  .logo {
    font-size: 16px;
  }
}

.links {
  color: #2e353b;
  text-decoration: none;
  font-style: normal;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.links:hover {
  color: #89939c;
}

.website-links {
  cursor: pointer;
  color: #2e353b;
  text-decoration: none;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: 600;
}

.website-links:hover {
  color: #89939c;
}

li:not(.logo) {
  margin-top: 3.5px;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: rgb(16, 17, 26);
  margin-top: 60px;
}

.footer-text {
  color: rgb(240, 243, 247);
  text-align: center;
}

@media only screen and (max-width: 400px) {
  .nav {
    padding: 18px 10px;
  }
}
